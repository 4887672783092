<template>
<div id=menu-header>
<header :class="['apbc-header-section', {'apbc-header-sticky': isSticky}]">
<div class=container>
<div class="row align-items-center">
<div class="col-lg-2 col-md-6 col-7">
<div class=apbc-logo-wrapper>
<a href=/ >
<picture>
<source type=image/avif :srcset="`
                      ${getLogoPath('sm', isSticky)}.avif 400w,
                      ${getLogoPath('md', isSticky)}.avif 800w,
                      ${getLogoPath('', isSticky)}.avif 1200w
                    `" :sizes=imageSizes fetchpriority=high>
<img :src=logoPath :alt="$t('header.logo')" class=logo-image :class="{ 'loaded': imageLoaded }" width=150 height=30 @load=handleImageLoad fetchpriority=high decoding=async>
</picture>
</a>
</div>
</div>
<div class="col-lg-8 apbc-desktop-menu">
<nav class=apbc-nav-menu>
<ul>
<li v-for="item in menuItems" :key=item.section :class="{ 'active': isActiveRoute(item.path) }">
<a v-if="item.path === '/'" href=/ @click="handleNavigation($event, item.path)" class=nav-link>
{{ $t(`header.${item.section}`) }}
</a>
<RouterLink v-else :to=item.path @click=closeMenu class=nav-link>
{{ $t(`header.${item.section}`) }}
</RouterLink>
</li>
</ul>
</nav>
</div>
<div class="col-lg-2 col-md-6 col-5 d-flex justify-content-end align-items-center">
<div class="apbc-lang-menu d-none d-lg-flex">
<ul class="d-flex align-items-center">
<li v-for="lang in languages" :key=lang class=language-switcher>
<button :class="{ 'selected': locale === lang }" @click=changeLanguage(lang)>
{{ lang.toUpperCase() }}
</button>
<span v-if="lang === 'en'" class=separator-header>|</span>
</li>
</ul>
</div>
<button class="apbc-mobile-menu-btn d-lg-none" @click=toggleMobileMenu aria-label="Toggle menu">
<i class="fas fa-bars"></i>
</button>
</div>
</div>
</div>
</header>
<div class=apbc-mobile-navigation :class="{ 'open': isMobileMenuOpen }">
<div class=apbc-mobile-menu-wrapper>
<button class=apbc-mobile-menu-close @click=toggleMobileMenu aria-label="Close menu">
<i class="fas fa-times"></i>
</button>
<nav class=mobile-nav>
<ul class=mobile-menu-list>
<li v-for="item in menuItems" :key=item.section :class="{ 'active': isActiveRoute(item.path) }">
<RouterLink :to=item.path @click=closeMenu class=nav-link>
{{ $t(`header.${item.section}`) }}
</RouterLink>
</li>
</ul>
<div class=mobile-lang-menu>
<ul class="d-flex align-items-center justify-content-center">
<li v-for="lang in languages" :key=lang class=language-switcher>
<button :class="{ 'selected': locale === lang }" @click=changeLanguage(lang)>
{{ lang.toUpperCase() }}
</button>
<span v-if="lang === 'en'" class=separator-header>|</span>
</li>
</ul>
</div>
</nav>
</div>
</div>
</div>
</template>
<script setup>
import{ref as e,computed as o,onMounted as a,onUnmounted as l}from"vue";import{useI18n as t}from"vue-i18n";import{useRouter as r}from"vue-router";let{locale:i}=t(),router=r(),isSticky=e(!1),isMobileMenuOpen=e(!1),imageLoaded=e(!1),languages=["en","id"],imageSizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px",menuItems=[{section:"home",path:"/"},{section:"about",path:"/AboutPage"},{section:"product",path:"/ProdukPage"},{section:"blog",path:"/BlogPura"},{section:"contactus",path:"/ContactUs"}],getLogoPath=(e="",o=!1)=>{let a=e?`_${e}`:"",l=o?"_Black":"";return`/assets/img/business-4/LOGO${l}${a}`},logoPath=o(()=>`${getLogoPath("",isSticky.value)}.avif`),isHomePage=o(()=>"/"===router.currentRoute.value.path),handleImageLoad=()=>{imageLoaded.value=!0},handleScroll=()=>{isSticky.value=window.scrollY>0},isActiveRoute=e=>router.currentRoute.value.path===e,scrollToTop=()=>{window.scrollTo({top:0,behavior:"smooth"})},handleNavigation=async(e,o)=>{("/"!==o||isHomePage.value)&&(e.preventDefault(),window.location.href="/"),closeMenu(),scrollToTop()},changeLanguage=e=>{i.value=e,localStorage.setItem("preferred-language",e),isHomePage.value&&window.location.reload(),scrollToTop()},toggleMobileMenu=()=>{isMobileMenuOpen.value=!isMobileMenuOpen.value,document.body.style.overflow=isMobileMenuOpen.value?"hidden":""},closeMenu=()=>{isMobileMenuOpen.value=!1,document.body.style.overflow=""};a(()=>{window.addEventListener("scroll",handleScroll,{passive:!0});let e=localStorage.getItem("preferred-language");e&&languages.includes(e)&&(i.value=e),router.afterEach(scrollToTop)}),l(()=>{window.removeEventListener("scroll",handleScroll),document.body.style.overflow=""});
</script>
<style scoped>
@import '@/assets/css/component/HeaderCom.css';
</style>
<template>
<div class=contact-maps>
<h2>{{ $t('ContactUs.title4') }}</h2>
<hr class=gold-line>
<h5>{{ $t('ContactUs.subtitle4') }}</h5>
<h5>{{ $t('ContactUs.subtitle5') }}</h5>
<div class=maps-container>
<div class=map>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2801.1794570909474!2d110.8227133700839!3d-6.8345992150953405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e70c577af3c49cf%3A0x935e5ec3595fa214!2sPura%20Unit%20Rotogravure%203%20(%20MICS%20)!5e0!3m2!1sid!2sid!4v1718958288837!5m2!1sid!2sid" loading=lazy referrerpolicy=no-referrer-when-downgrade title="Pura Location Map">
</iframe>
</div>
<div class=info>
<h3>{{ $t('ContactUs.title6') }}</h3>
<ul class=contact-list>
<li class=contact-item v-for="(location, index) in locations" :key=index>
<a :href=location.mapUrl target=_blank rel=noopener class=location-link>
<div class=contact-header>
<img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35'%3E%3Cpath fill='%23858585' d='M17.5 0C11.2 0 6.1 5.1 6.1 11.4c0 8.5 11.4 23.6 11.4 23.6s11.4-15.1 11.4-23.6C28.9 5.1 23.8 0 17.5 0zm0 15.6c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2z'/%3E%3C/svg%3E" alt="Location marker" width=35 height=35 class=contact-icon loading=lazy>
<h3 class=contact-title>{{ location.title }}</h3>
</div>
</a>
<p class=contact-address>{{ location.address }}</p>
</li>
</ul>
</div>
</div>
</div>
</template>  
<script>
export default{name:"ContactMaps",data:()=>({locations:[{title:"PURA GROUP Marketing Jakarta",address:"Graha Pura, Jl. Panconron Indah No. 5A, Jakarta Selatan",mapUrl:"https://maps.app.goo.gl/p6oBtjAaQERGgU4o8"},{title:"PURA GROUP Marketing Jawa Barat",address:"Jl. Kopo No. 6B, Komplek Kopo Mas Regency Blok N 11 D, Bandung",mapUrl:"https://maps.app.goo.gl/AbQxffCFGAaWk19r5"},{title:"PURA GROUP Marketing Jawa Tengah",address:"Jl. Dr. Ramaelan No. 20, Magersari, Pemalang, Kudus",mapUrl:"https://maps.app.goo.gl/8iWzbC5dXpffNXF79"},{title:"PURA GROUP Marketing Jawa Timur",address:"Jalan Raya Panjang Jiwo Permai No.19-20 Blok 1B, Surabaya",mapUrl:"https://maps.app.goo.gl/w52sttk62LnEKPgh6"}]}),methods:{generateLocationSchema(){let a={"@context":"https://schema.org","@type":"Organization",name:"Pura Material Innovations and Cigarette Solutions (MICS)",url:"https://puracigarettesolutions.com",logo:"https://puracigarettesolutions.com/assets/img/business-4/LOGO.avif",location:this.locations.map(a=>({"@type":"Place",name:a.title,address:{"@type":"PostalAddress",streetAddress:a.address,addressCountry:"ID"},hasMap:a.mapUrl,geo:{"@type":"GeoCoordinates",latitude:"-6.8345992150953405",longitude:"110.8227133700839"}})),contactPoint:this.locations.map(a=>({"@type":"ContactPoint",contactType:"sales",name:a.title,areaServed:{"@type":"GeoCircle",geoMidpoint:{"@type":"GeoCoordinates",latitude:"-6.8345992150953405",longitude:"110.8227133700839"}}}))},t=document.querySelector('script[type="application/ld+json"]');t&&t.remove();let e=document.createElement("script");e.type="application/ld+json",e.innerHTML=JSON.stringify(a),document.head.appendChild(e)}},mounted(){this.generateLocationSchema()},beforeUnmount(){let a=document.querySelector('script[type="application/ld+json"]');a&&a.remove()}};
</script>
<style src="@/assets/css/contact/ContactMaps.css" scoped></style>
<template>
    <nav class="bg-white border-b border-gray-200 px-4 py-2.5 flex justify-between items-center sticky top-0 z-40">
      <!-- Left side navbar -->
      <ul class="flex space-x-4">
        <li>
          <button @click="$emit('toggle-sidebar')" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa-bars"></i>
          </button>
        </li>
        <li class="hidden sm:block">
          <a href="index3.html" class="text-gray-700 hover:text-gray-900">Home</a>
        </li>
        <li class="hidden sm:block">
          <a href="#" class="text-gray-700 hover:text-gray-900">Contact</a>
        </li>
      </ul>
  
      <!-- Right side navbar -->
      <ul class="flex items-center space-x-4">
        <!-- Search -->
        <li class="relative">
          <a href="#" role="button" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa-search"></i>
          </a>
          <div class="hidden absolute right-0 top-full mt-2 w-64 bg-white rounded-md shadow-lg p-2">
            <form class="flex">
              <div class="flex w-full">
                <input class="flex-grow px-3 py-1 text-sm border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                       type="search" placeholder="Search" aria-label="Search">
                <div class="flex">
                  <button class="px-2 py-1 bg-gray-200 text-gray-700 hover:bg-gray-300" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                  <button class="px-2 py-1 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-r-md" type="button">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
  
        <!-- Messages -->
        <li class="relative">
          <a href="#" class="text-gray-700 hover:text-gray-900 relative">
            <i class="far fa-comments"></i>
            <span class="absolute -top-2 -right-2 inline-flex items-center justify-center h-5 w-5 text-xs font-bold text-white bg-red-600 rounded-full">3</span>
          </a>
          <div class="hidden absolute right-0 top-full mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden">
            <!-- Message items -->
            <a href="#" class="block p-3 hover:bg-gray-50">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-gray-900 flex justify-between">
                    Brad Diesel
                    <span class="text-red-500 ml-2"><i class="fas fa-star"></i></span>
                  </h3>
                  <p class="text-sm text-gray-800">Call me whenever you can...</p>
                  <p class="text-xs text-gray-500 mt-1"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                </div>
              </div>
            </a>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="block p-3 hover:bg-gray-50">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-gray-900 flex justify-between">
                    John Pierce
                    <span class="text-gray-500 ml-2"><i class="fas fa-star"></i></span>
                  </h3>
                  <p class="text-sm text-gray-800">I got your message bro</p>
                  <p class="text-xs text-gray-500 mt-1"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                </div>
              </div>
            </a>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="block p-3 hover:bg-gray-50">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-gray-900 flex justify-between">
                    Nora Silvester
                    <span class="text-yellow-500 ml-2"><i class="fas fa-star"></i></span>
                  </h3>
                  <p class="text-sm text-gray-800">The subject goes here</p>
                  <p class="text-xs text-gray-500 mt-1"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                </div>
              </div>
            </a>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="block py-2 text-center text-sm text-blue-600 bg-gray-50 hover:bg-gray-100">See All Messages</a>
          </div>
        </li>
  
        <!-- Notifications -->
        <li class="relative">
          <a href="#" class="text-gray-700 hover:text-gray-900 relative">
            <i class="far fa-bell"></i>
            <span class="absolute -top-2 -right-2 inline-flex items-center justify-center h-5 w-5 text-xs font-bold text-white bg-yellow-500 rounded-full">15</span>
          </a>
          <div class="hidden absolute right-0 top-full mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden">
            <span class="block py-2 text-center text-sm font-medium bg-gray-100">15 Notifications</span>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="flex items-center justify-between p-3 hover:bg-gray-50">
              <div>
                <i class="fas fa-envelope mr-2 text-gray-500"></i> 4 new messages
              </div>
              <span class="text-xs text-gray-500">3 mins</span>
            </a>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="flex items-center justify-between p-3 hover:bg-gray-50">
              <div>
                <i class="fas fa-users mr-2 text-gray-500"></i> 8 friend requests
              </div>
              <span class="text-xs text-gray-500">12 hours</span>
            </a>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="flex items-center justify-between p-3 hover:bg-gray-50">
              <div>
                <i class="fas fa-file mr-2 text-gray-500"></i> 3 new reports
              </div>
              <span class="text-xs text-gray-500">2 days</span>
            </a>
            <div class="border-t border-gray-200"></div>
            
            <a href="#" class="block py-2 text-center text-sm text-blue-600 bg-gray-50 hover:bg-gray-100">See All Notifications</a>
          </div>
        </li>
  
        <!-- Fullscreen -->
        <li>
          <a href="#" role="button" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
  
        <!-- Control sidebar -->
        <li>
          <a href="#" role="button" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa-th-large"></i>
          </a>
        </li>
      </ul>
    </nav>
  </template>
<template>
  <div class="w-64 bg-gray-800 text-white h-screen overflow-y-auto">
    <!-- Search -->
    <div class="p-4">
      <div class="relative">
        <input 
          class="w-full pl-10 pr-4 py-2 bg-gray-700 text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
          type="search" 
          placeholder="Search" 
          aria-label="Search"
        >
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <i class="fas fa-search fa-fw text-gray-400"></i>
        </div>
      </div>
    </div>
    
    <!-- Menu -->
    <nav class="mt-4">
      <ul class="space-y-1">
        <li v-for="item in accessibleMenuItems" :key="item.id">
          <!-- Router link untuk semua menu kecuali Logout -->
          <router-link 
            v-if="item.title !== 'Logout'" 
            :to="item.link" 
            class="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
            :class="{ 'bg-gray-700 text-white': item.link === $route.path }"
          >
            <i :class="item.icon + ' w-5 h-5 mr-3'"></i>
            <span>{{ item.title }}</span>
          </router-link>
          
          <!-- Button khusus untuk Logout -->
          <button 
            v-else
            @click="handleLogout"
            class="w-full flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors text-left"
          >
            <i :class="item.icon + ' w-5 h-5 mr-3'"></i>
            <span>{{ item.title }}</span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  computed: {
    loggedInUserRole() {
      return this.$store.getters.loggedInUserRole;
    },
    accessibleMenuItems() {
      return this.menuItems.filter(i => i.allowedRoles.includes(this.loggedInUserRole));
    }
  },
  data: () => ({
    isLoggingOut: false,
    menuItems: [
      {
        id: 1,
        title: "Dashboard",
        link: "/DashboardDs",
        icon: "fas fa-tachometer-alt",
        allowedRoles: [1, 2]
      },
      {
        id: 2,
        title: "Artikel",
        link: "/TampilanArtikel",
        icon: "fas fa-book",
        allowedRoles: [1, 2]
      },
      {
        id: 3,
        title: "Produk",
        link: "/TampilanProduk",
        icon: "far fa-image",
        allowedRoles: [1, 2]
      },
      {
        id: 4,
        title: "Detail Produk",
        link: "/TampilanDetailProduk",
        icon: "far fa-image",
        allowedRoles: [1, 2]
      },
      {
        id: 5,
        title: "Ketegori",
        link: "/TampilanKategori",
        icon: "fas fa-book",
        allowedRoles: [1]
      },
      {
        id: 6,
        title: "Ulang Tahun",
        link: "/UlangTahun",
        icon: "far fa-calendar-alt",
        allowedRoles: [1, 2]
      },
      {
        id: 7,
        title: "Etiket",
        link: "/TampilanEtiket",
        icon: "far fa-envelope",
        allowedRoles: [1, 2]
      },
      {
        id: 8,
        title: "CTP",
        link: "/TampilanCtp",
        icon: "fas fa-edit",
        allowedRoles: [1, 2]
      },
      {
        id: 9,
        title: "Cigarette Paper",
        link: "/TampilanCp",
        icon: "fas fa-edit",
        allowedRoles: [1, 2]
      },
      {
        id: 10,
        title: "Filter Tips",
        link: "/TampilanFilter",
        icon: "far fa-envelope",
        allowedRoles: [1, 2]
      },
      {
        id: 11,
        title: "PRE-ROLLED CONES",
        link: "/TampilanCones",
        icon: "far fa-envelope",
        allowedRoles: [1, 2]
      },
      {
        id: 12,
        title: "Sosial Media",
        link: "/TampilanSosmed",
        icon: "fas fa-edit",
        allowedRoles: [1, 2]
      },
      {
        id: 13,
        title: "Question",
        link: "/TampilanQuestion",
        icon: "fas fa-edit",
        allowedRoles: [1, 2]
      },
      {
        id: 14,
        title: "Customer",
        link: "/TampilanCustomer",
        icon: "fas fa-edit",
        allowedRoles: [1, 2]
      },
      {
        id: 15,
        title: "Promo",
        link: "/TampilanPromo",
        icon: "fas fa-edit",
        allowedRoles: [1, 2]
      },
      {
        id: 16,
        title: "Users",
        link: "/TampilanUsers",
        icon: "far fa-plus-square",
        allowedRoles: [1]
      },
      {
        id: 17,
        title: "Logout",
        link: "/loginds",
        icon: "fas fa-sign-out-alt",
        allowedRoles: [1, 2]
      }
    ]
  }),
  methods: {
    async handleLogout() {
      // Mencegah multiple klik
      if (this.isLoggingOut) return;
      
      this.isLoggingOut = true;
      
      try {
        // Ambil token dari localStorage
        const token = localStorage.getItem('token');
        
        // Panggil API logout jika token ada
        if (token) {
          try {
            await axios.post('http://192.168.12.117:3000/api/users/logout', {}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            console.log('Server logout successful');
          } catch (apiError) {
            // Lanjutkan dengan logout lokal meskipun API gagal
            console.warn('API logout failed, continuing with client logout:', apiError);
          }
        }
        
        // Hapus token dari localStorage
        localStorage.removeItem('token');
        
        // Dispatch action logout di store
        await this.$store.dispatch('logout');
        
        // Arahkan ke halaman login
        this.$router.push('/LoginDs');
        
        console.log('Logout completed successfully');
      } catch (error) {
        console.error('Logout error:', error);
      } finally {
        this.isLoggingOut = false;
      }
    }
  }
};
</script>